<template>
  <main id="certificate">
    <v-sheet id="pledge-cert">
      <v-container class="fill-height">
        <v-row justify="center">  
          <v-col cols="12" md="8">
            <v-card class="rounded-lg" color="grey lighten-3" elevation="4">
              <v-toolbar dense flat>
                <v-toolbar-title>
                  <h6 class="text-h6 font-weight-light">
                    Your Certificate
                  </h6>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="downloadCertificate">
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>
                <v-btn icon @click="printCertificate">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
                <v-btn icon @click="shareModal = true">
                  <v-icon>
                    mdi-share-variant
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-img id="cert-preview" :src="finalImage" :aspect-ratio="1.34327">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet id="thank-you">
      <v-container class="py-8" style="max-width: 1280px" fill-height>
        <v-row justify="center">
            <v-col cols="12">
              <div class="text-h6 mb-4">
                Thank you for signing the pledge!
              </div>
              <div class="text-body-1">
                Now that you have signed the pledge, check out these reminders to help you live Love God Love Each Other.
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-card class="rounded-lg mb-6" flat>
              <v-img :aspect-ratio="3.2">
                <video autoplay="autoplay" loop="loop" muted="muted" style="width: 100%">
                  <source :src="require('../assets/video/product/wristband.mp4')" type="video/mp4"> Your browser does not support the video tag. 
                </video>
              </v-img>
              <v-card-actions>
                <v-btn class="pa-8 mx-auto" color="error" href="https://shop.myonerule.com/" large data-aos="fade-up">
                  Shop Reminders
                  <v-icon right dark>
                    mdi-cart
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
            </v-col>
          </v-row>
      </v-container>
    </v-sheet>

    <!-- Social Sharing Dialog -->
    <v-dialog v-model="shareModal" persistent max-width="290">
      <v-card>
        <v-card-title class="justify-center">
          <h6 class="text-h6 text-center mb-2">
            Spread the Word
          </h6>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <ShareNetwork
            style="text-decoration: none"
            network="facebook"
            url="https://myonerule.com"
            title="Check out My One Rule!"
            hashtags="myonerule"
            >
            <v-btn class="mb-6" color="primary" block>
              Facebook
            </v-btn>
          </ShareNetwork>
          <ShareNetwork
            style="text-decoration: none"
            network="twitter"
            url="https://myonerule.com"
            title="Check out My One Rule!"
            hashtags="myonerule"
            >
            <v-btn class="mb-6" color="blue lighten-1" block dark>
              Twitter
            </v-btn>
          </ShareNetwork>
          <ShareNetwork
            style="text-decoration: none"
            network="email"
            url="https://myonerule.com"
            title="Check out My One Rule!"
            hashtags="myonerule"
            >
            <v-btn color="black" block dark>
              Email
            </v-btn>
          </ShareNetwork>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="green darken-1" text @click="shareModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <canvas id="canvas" style="display: none"/>
    <div id="print" style="display: none">
      <img :src="finalImage" style="width: 100%" alt="">
    </div>
  </main>
</template>

<script>
import ReImg from '../utils/reimg';

export default {
  name: "Certificate",
  data: () => ({
    canvas: null,
    finalImage: '',
    shareModal: false,

    // Social links.
    socialButtons: [
      { label: "Facebook",  color: "blue darken-2",       icon: "mdi-facebook",  href: "https://www.facebook.com/my1rule/" },
      { label: "Instagram", color: "purple darken-1",     icon: "mdi-instagram", href: "https://www.instagram.com/my1rule/" },
      { label: "Twitter",   color: "light-blue darken-1", icon: "mdi-twitter",   href: "https://twitter.com/MyOneRule" }
    ],
  }),
  methods: {
    // Renders a certificate with a given name, date, and scale factor.
    // Should always just render at 1.0 but it's in there cause why not.
    renderCertificate: function(name, date, scale) {
      var canvas = document.getElementById('canvas');
      var context = canvas.getContext('2d');
      var cert = document.getElementById('cert');

      // Config constants.
      const fontSize = 96;
      const nameRect = { x: 1068, y: 1282, w: 996, h: 96 }
      const dateRects = [
        { x: 1362, y: 1138, w: 117, h: fontSize }, // Month
        { x: 1521, y: 1138, w: 117, h: fontSize }, // Day
        { x: 1695, y: 1138, w: 117, h: fontSize }, // Year
      ]

      // Get the native size of the certificate image.
      var certSize = { x: cert.width, y: cert.height }

      // Set the canvas to the appropriate size.
      canvas.width = certSize.x * scale;
      canvas.height = certSize.y * scale;

      // Configure the font.
      context.font = `${Math.round(fontSize * scale)}px Georgia`;
      context.textAlign = "center";

      // Draw the certificate image to the canvas.
      context.drawImage(cert, 0, 0, canvas.width, canvas.height);

      // Draw text for each date component.
      context.fillText(date[0], (dateRects[0].x + dateRects[0].w / 2) * scale, dateRects[0].y * scale);
      context.fillText(date[1], (dateRects[1].x + dateRects[1].w / 2) * scale, dateRects[1].y * scale);
      context.fillText(date[2], (dateRects[2].x + dateRects[2].w / 2) * scale, dateRects[2].y * scale);

      // Draw text for the name.
      context.fillText(name, (nameRect.x + nameRect.w / 2) * scale, nameRect.y * scale, nameRect.w * scale);

      // Spit out the rendered image as a massive base64 string :D
      this.canvas = canvas;
      return canvas.toDataURL();
    },

    downloadCertificate: function() {
      ReImg.fromCanvas(this.canvas).downloadPng("certificate");
    },

    printCertificate: function () {
      this.$htmlToPaper("print");
    }
  },
  mounted() {
    // Try and grab the necessary query params.
    var params = this.$route.query;
    var name = params.name;
    var date = params.date;

    // Redirect to the home page if something is missing.
    if (!name || !date) {
      this.$router.push('/');
    }

    // Split the date and truncate the year.
    var dateStrings = date.split('/');
    dateStrings[2] = dateStrings[2].substr(2, 2);

    // Redirect if the date is not composed properly.
    if (dateStrings.length != 3) {
      this.$router.push('/');
    }

    // Render the certificate with the provided params.
    var cert = new Image();
    cert.id = "cert";
    cert.style = "display: none";
    cert.onload = async () => {
      this.finalImage = this.renderCertificate(name, dateStrings, 1.0);
    }
    cert.src=require('../assets/img/cert/cert.png');
    document.getElementById('pledge-cert').appendChild(cert);
  }
}
</script>